import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ContentChild, TemplateRef } from '@angular/core';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { MenuOption } from '../../../shared/models/menu-option';
import { NgClass, NgIf, NgTemplateOutlet, NgFor } from '@angular/common';

@Component({
    selector: 'select-menu',
    templateUrl: 'select-menu.component.html',
    styleUrls: ['select-menu.component.css'],
    standalone: true,
    imports: [NgClass, MatMenuModule, NgIf, NgTemplateOutlet, NgFor]
})
export class SelectMenuComponent implements OnInit {
    
    @Input() selectedOption: MenuOption = new MenuOption("", "");
    @Input() menuOptions: MenuOption[];
    @Input() contentStyle: string;
    @Input() triggerButtonIconClass: string;
    @Input() triggerButtonActiveIconClass: string;
    @Input() toggleMenuIconClass: string; // must implement classes 'opened', 'closed'
    @Input() shouldOpen: boolean = true;
    @Input() showSelectedOption: boolean = true;

    @Output() onSelection: EventEmitter<MenuOption>;
    
    @Output() menuState: EventEmitter<boolean>;
    
    @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

    /**
     * A template reference of a custom menu option
     */
    @ContentChild('optionTemplate', {read: TemplateRef})
    optionTemplateRef: TemplateRef<any>;

    /**
     * A template reference of a custom menu trigger button
     */
    @ContentChild('triggerTemplate', {read: TemplateRef})
    triggerTemplateRef: TemplateRef<any>;
    
    isMenuOpen: boolean;

    constructor() {
        this.onSelection = new EventEmitter<MenuOption>();
        this.menuState = new EventEmitter<boolean>();
        this.isMenuOpen = false;
    }

    ngOnInit() {
        this.triggerButtonActiveIconClass = this.triggerButtonActiveIconClass 
            ? this.triggerButtonActiveIconClass
            : this.triggerButtonIconClass;
        this.setActiveMenuOption();
    }

    setActiveMenuOption = () => {
        if (this.menuOptions && this.selectedOption) {
            this.menuOptions.forEach(option => {
                if (option.value === this.selectedOption.value) {
                    option.isActive = true;
                }
            });
        }
    }

    openMenu = () => {
        if (this.trigger) {
            this.trigger.openMenu();
        }
        this.toggleMenu(true);
    }

    toggleMenu = (state: boolean) => {
        this.isMenuOpen = state;
        this.menuState.emit(state);
    }

    select = (option: MenuOption) => {
        this.selectedOption.isActive = false;
        const selectedIndex = this.menuOptions.findIndex(option => option.value === this.selectedOption.value);
        if (selectedIndex !== -1) {
            this.menuOptions[selectedIndex].isActive = false;
        }

        if (option.clickAction) {
            option.clickAction(option);
        } else {
            this.onSelection.emit(option);
        }
        
    }

    onMouseEnter = (option: MenuOption) => {
        option.isActive = true;
    }

    onMouseLeave = (option: MenuOption) => {
        option.isActive = (!this.selectedOption || this.selectedOption.value !== option.value) ? false : option.isActive;
    }

}
