<div 
    [ngClass]="{
        'unclickable': !shouldOpen || menuOptions && menuOptions.length === 0
    }"
    class="select-menu-container"
    (click)="openMenu()">
    <div
        class="triggers"
        mat-button 
        [matMenuTriggerFor]="mainMenu"
        (menuClosed)="toggleMenu(false)"
        (menuOpened)="toggleMenu(true)">

        <ng-container *ngIf="!triggerTemplateRef">
            <span class="trigger-button-icon">
                <i [ngClass]="!isMenuOpen ? triggerButtonIconClass : triggerButtonActiveIconClass">
                </i>
            </span>
            <span 
                *ngIf="showSelectedOption"
                class="trigger-button-text">
                {{ selectedOption?.name }}
            </span>
            <span
                *ngIf="shouldOpen"
                class="trigger-button-toggle-icon">
                <i 
                    [class]="toggleMenuIconClass"
                    [ngClass]="{
                        'opened': isMenuOpen, 
                        'closed': !isMenuOpen
                    }">
                </i>
            </span>
        </ng-container>

        <!-- Slot for custom trigger template -->
        <ng-container
            [ngTemplateOutlet]="triggerTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: selectedOption }">
        </ng-container>

    </div>

    <mat-menu
        #mainMenu="matMenu"
        [class]="contentStyle">
        <button 
            *ngFor="let option of menuOptions; index as i" 
            [ngClass]="option.isActive && option.activeStyle 
                ? 'mat-menu-item ' + option.activeStyle 
                : 'mat-menu-item ' + option.style"
            mat-menu-item
            disableRipple="true"
            (click)="select(option)"
            (mouseenter)="onMouseEnter(option)"
            (mouseleave)="onMouseLeave(option)">
            <ng-container
                *ngIf="!optionTemplateRef">
                <span class="option-icon">
                    <i [ngClass]="option.isActive && option.sideIconActiveClass 
                        ? option.sideIconActiveClass
                        : option.sideIconClass">
                    </i>
                </span>
                {{ option.name }}
            </ng-container>
            <!-- Slot for custom option template -->
            <ng-container
                [ngTemplateOutlet]="optionTemplateRef"
                [ngTemplateOutletContext]="{ $implicit: option, index: i }">
            </ng-container>
        </button>
    </mat-menu>
</div>